import { GridOptions } from 'ag-grid-community';
import {
  DateTimePickerCell,
  InputCell,
  NumericCell,
  RateAddressFromAutoCompleteCell,
  RateAddressToAutoCompleteCell,
  RateRateCommodityAutoCompleteCell,
  SelectServiceTypeCell,
  SelectUnitOfMeasuerTypeCell,
} from 'components/ReactGrid/editors';
import { FullRowAction } from 'components/ReactGrid/renderers';
import CustomCell from 'components/ReactGrid/renderers/CustomCell';
import CustomTooltipRateCommodity from 'components/ReactGrid/renderers/CustomTooltipRateCommodity';
import { ICustomCellRendererParams } from 'components/ReactGrid/renderers/types';
import {
  GenericCodeBookingServiceType,
  UnitOfMeasureTypeOptions,
} from 'components/config/types/common';
export const ratesGridConfig = {
  toolbar: {
    actions: [
      {
        id: 'create',
        label: 'Add',
        tooltip: 'Add new item',
        skipPermission: true,
      },
      {
        id: 'delete',
        tooltip: 'Delete selected records',
        skipPermission: true,
      },
    ],
  },
  gridId: 'rate',
  gridClassName: 'grid-container',
  gridHeight: '30vh',
  gridOptions: {
    rowSelection: 'multiple',
    suppressContextMenu: true,
    enablePivot: true,
    components: {
      CustomCell,
      FullRowAction,
      InputCell,
      NumericCell,
      SelectServiceTypeCell,
      DateTimePickerCell,
      RateRateCommodityAutoCompleteCell,
      RateAddressToAutoCompleteCell,
      RateAddressFromAutoCompleteCell,
      SelectUnitOfMeasuerTypeCell,
    },

    //stopEditingWhenCellsLoseFocus: true,
    maintainColumnOrder: true,
    editType: 'fullRow',
    defaultColDef: {
      flex: 1,
      resizable: true,
      sortable: true,
      // suppressMenu: true,
      cellRenderer: 'CustomCell',
      gridId: 'rate',
      editable: true,
      tooltipComponent: CustomTooltipRateCommodity,
      tooltipField: 'rateCommodityId',
      tooltipComponentParams: { color: '#ececec' },
    },
    columnDefs: [
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id',
        pinned: 'left',
        width: 80,
        editable: false,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        showDisabledCheckboxes: true,
      },
      {
        headerName: 'Address From',
        field: 'addressFrom',
        colId: 'addressFrom',
        pinned: 'left',
        width: 150,
        cellEditor: 'RateAddressFromAutoCompleteCell',
        valueFormatter: params => {
          const addressFrom = params?.data?.addressFrom;
          if (!addressFrom) return '';
          const { postCode, placeType, country } = addressFrom;
          if (placeType === 'country') {
            return country || '';
          }
          const locality = addressFrom?.addressCodeLocality?.name || '';
          const place = addressFrom?.addressCodePlace?.name || '';
          const region = addressFrom?.addressCodeRegion?.name || '';
          const countryCode = addressFrom?.addressCodeCountry?.code || '';
          const paramList = [
            locality,
            place,
            region,
            countryCode,
            postCode,
          ].filter(Boolean);
          return paramList.join(', ');
        },

        valueGetter: params => {
          const addressFrom = params?.data?.addressFrom;
          if (!addressFrom) return '';
          const { postCode } = addressFrom;
          const locality = addressFrom?.addressCodeLocality?.name || '';
          const place = addressFrom?.addressCodePlace?.name || '';
          const region = addressFrom?.addressCodeRegion?.name || '';
          const countryName = addressFrom?.addressCodeCountry?.name || '';
          const paramList = [
            locality,
            place,
            region,
            countryName,
            postCode,
          ].filter(Boolean);
          return paramList.join(', ');
        },
      },
      {
        headerName: 'Address To',
        field: 'addressTo',
        colId: 'addressTo',
        pinned: 'left',
        width: 150,
        cellEditor: 'RateAddressToAutoCompleteCell',
        valueFormatter: params => {
          const addressTo = params?.data?.addressTo;
          if (!addressTo) return '';
          const { postCode, placeType, country } = addressTo;
          const locality = addressTo?.addressCodeLocality?.name || '';
          const place = addressTo?.addressCodePlace?.name || '';
          const region = addressTo?.addressCodeRegion?.name || '';
          const countryCode = addressTo?.addressCodeCountry?.code || '';
          const paramList = [
            locality,
            place,
            region,
            countryCode,
            postCode,
          ].filter(Boolean);
          if (placeType === 'country') {
            return country;
          }
          return paramList.join(', ');
        },

        valueGetter: params => {
          const addressTo = params?.data?.addressTo;
          if (!addressTo) return '';
          const { postCode } = addressTo;
          const locality = addressTo?.addressCodeLocality?.name || '';
          const place = addressTo?.addressCodePlace?.name || '';
          const region = addressTo?.addressCodeRegion?.name || '';
          const countryName = addressTo?.addressCodeCountry?.name || '';
          const paramList = [
            locality,
            place,
            region,
            countryName,
            postCode,
          ].filter(Boolean);
          return paramList.join(', ');
        },
      },
      {
        headerName: 'Minimum',
        field: 'minimum',
        width: 50,
        cellEditor: 'NumericCell',
      },
      {
        headerName: 'Maximum',
        field: 'maximum',
        width: 50,
        cellEditor: 'NumericCell',
      },
      {
        headerName: 'UOM',
        field: 'unitOfMeasureId',
        width: 50,
        cellEditor: 'SelectUnitOfMeasuerTypeCell',
        valueGetter: params => {
          const selectedItem = UnitOfMeasureTypeOptions?.find(
            item => item?.value === params?.data?.unitOfMeasureId,
          );
          return selectedItem?.label;
        },
      },
      {
        headerName: 'Service',
        field: 'serviceTypeId',
        width: 50,
        cellEditor: 'SelectServiceTypeCell',
        valueGetter: params => {
          const selectedItem = GenericCodeBookingServiceType?.find(
            item => item?.value === params?.data?.serviceTypeId,
          );
          return selectedItem?.label;
        },
      },
      {
        headerName: 'Commodity',
        field: 'rateCommodityId',
        width: 50,
        cellEditor: 'RateRateCommodityAutoCompleteCell',
        valueGetter: params => {
          return params?.data?.rateCommodity?.name;
        },
      },
      {
        headerName: 'RD',
        field: 'rd',
        width: 50,
        cellEditor: 'NumericCell',
      },
      {
        headerName: 'Tonne',
        field: 'tonne',
        width: 50,
        cellEditor: 'NumericCell',
      },
      {
        headerName: 'Detail',
        field: 'detail',
        width: 50,
        cellEditor: 'InputCell',
      },
      {
        headerName: 'Start On',
        field: 'startDate',
        width: 50,
        cellRendererParams: {
          type: 'datetime',
        } as ICustomCellRendererParams,
        cellEditor: 'DateTimePickerCell',
      },
      {
        headerName: 'End On',
        field: 'endDate',
        width: 50,
        cellRendererParams: {
          type: 'datetime',
        } as ICustomCellRendererParams,
        cellEditor: 'DateTimePickerCell',
      },
      {
        headerName: 'Action',
        colId: 'action',
        cellRenderer: 'FullRowAction',
        width: 70,
        pinned: 'right',
        editable: false,
      },
    ],
  } as GridOptions,
};
