import { DatePicker, Input, InputNumber } from 'antd';
import Field from 'components/Form/Field';
import FormControlledCommoditySelect from 'components/common/FormControlledCommoditySelect';
import FormControlledSelect from 'components/common/FormControlledSelect';
import WithFormHooksController from 'components/common/WithFormHooksController';
import DateFormats from 'components/config/date';
import { UnitOfMeasureTypeOptions } from 'components/config/types/common';
import { useFormHooks } from 'context/FormHooksContext';
import useGenericCodeLists from 'context/genericCodeLists/hooks';
import React from 'react';
import FormControlledAddressAutoComplete from '../../Components/FormControlledMapAddressAutoComplete';

export default function DetailsForm() {
  const { control } = useFormHooks();
  const { genericCodeListData } = useGenericCodeLists();
  const serviceOptions = genericCodeListData?.BookingServiceType.map(el => {
    return { value: el.id, label: el.name };
  });

  return (
    <div className="grid gap-3 grid-cols-12 h-auto px-3 mt-3">
      <Field label="Address From" isRequired span={3}>
        <FormControlledAddressAutoComplete
          name="addressFromId"
          control={control}
        />
      </Field>
      <Field label="Address To" isRequired span={3}>
        <FormControlledAddressAutoComplete
          name="addressToId"
          control={control}
        />
      </Field>
      <Field label="Start Date" isRequired span={3}>
        <WithFormHooksController
          control={control}
          name="startDate"
          component={
            <DatePicker showTime format={DateFormats.asDisplayDateTime} />
          }
        />
      </Field>
      <Field label="End Date" isRequired span={3}>
        <WithFormHooksController
          control={control}
          name="endDate"
          component={
            <DatePicker showTime format={DateFormats.asDisplayDateTime} />
          }
        />
      </Field>
      <Field label="Service" isRequired span={3}>
        <FormControlledSelect
          name="serviceTypeId"
          options={serviceOptions}
          defaultValue={
            serviceOptions.find(el => el.label.toLowerCase() === 'standard')
              ?.value
          }
        />
      </Field>
      <Field label="Ton/M3" span={3}>
        <WithFormHooksController
          control={control}
          name="tonne"
          component={<InputNumber className="px-2 rounded-md w-full" />}
        />
      </Field>
      <Field label="Minimum" span={3}>
        <WithFormHooksController
          control={control}
          name="minimum"
          component={<InputNumber className="px-2 rounded-md w-full" />}
        />
      </Field>
      <Field label="Maximum" span={3}>
        <WithFormHooksController
          control={control}
          name="maximum"
          component={<InputNumber className="px-2 rounded-md w-full" />}
        />
      </Field>
      <Field label="RD" span={3}>
        <WithFormHooksController
          control={control}
          name="rd"
          component={<InputNumber className="px-2 rounded-md w-full" />}
        />
      </Field>
      <Field label="*Commodity" span={3}>
        <FormControlledCommoditySelect name="commodityId" control={control} />
      </Field>
      <Field label="Detail" span={3}>
        <WithFormHooksController
          control={control}
          name="detail"
          component={<Input className="px-2 rounded-md w-full" />}
        />
      </Field>
      <Field label="*Unit Of Measure Type" span={3}>
        <FormControlledSelect
          name="unitOfMeasureId"
          options={UnitOfMeasureTypeOptions}
        />
      </Field>
    </div>
  );
}
