import { GridOptions } from 'ag-grid-community';
import { NumericCell } from 'components/ReactGrid/editors';
import BooleanIntFloatingFilter from 'components/ReactGrid/filters/BooleanIntFloatingFilter';
import SelectorFloatingFilter from 'components/ReactGrid/filters/SelectorFloatingFilter';
import {
  BookingFlag,
  CheckedCell,
  CustomCell,
  DualCheckedCell,
} from 'components/ReactGrid/renderers';
import {
  getCellClass,
  getCellClassRules,
  getDateCellClassRules,
  getGoingToCellClassRules,
  getNextMilestoneColorRules,
  getNowAtCellClassRules,
  getStatusCellClassRules,
} from 'components/ReactGrid/renderers/styleFormatter';
import { ICustomCellRendererParams } from 'components/ReactGrid/renderers/types';
import { TGridColumnType } from 'components/ReactGrid/types';
import dayjs from 'dayjs';
import bookingAttributeColDefs from 'helpers/bookingAttributeColDefs';
import { sortByNumber } from 'helpers/comparators';
import {
  booleanSelectorFilterParams,
  dateFilterParams,
  numberFilterParams,
  selectorFilterParams,
  textFilterParams,
} from 'helpers/filterParams';
import BookingDetailExpand from './components/BookingDetailExpand';

export const invoicingGridOptions = {
  groupSelectsChildren: false,
  headerHeight: 58,
  rowSelection: 'multiple',
  multiSortKey: 'ctrl',
  singleClickEdit: true,
  enableRangeSelection: true,
  masterDetail: true,
  detailRowAutoHeight: true,
  detailCellRenderer: 'BookingDetailExpand',
  components: {
    BookingFlag,
    SelectorFloatingFilter,
    BooleanIntFloatingFilter,
    DualCheckedCell,
    NumericCell,
    BookingDetailExpand,
    CheckedCell,
    CustomCell,
  },
  sideBar: {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressSideButton: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true,
        },
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
      },
    ],
  },

  defaultColDef: {
    initialHide: false,
    enableRowGroup: true,
    enablePivot: true,
    tooltipComponent: 'SignatureTooltip',
    floatingFilter: true,
    menuTabs: ['filterMenuTab'],
    filter: 'agTextColumnFilter',
    filterParams: textFilterParams,
  },
  suppressScrollOnNewData: true,
  columnTypes: {
    [TGridColumnType.ClickableColumn]: { width: 60 },
    [TGridColumnType.EditableColumn]: {
      editable: true,
      cellClassRules: getCellClassRules(),
    },
  },
  columnDefs: [
    {
      headerName: 'ID',
      field: 'id',
      width: 75,
      filter: 'agNumberColumnFilter',
      filterParams: numberFilterParams,
      cellRenderer: 'agGroupCellRenderer',
    },
    {
      headerName: 'Flags',
      field: 'bookingFlags',
      colId: 'bookingFlag',
      cellRenderer: 'BookingFlag',
      width: 90,
      floatingFilterComponent: 'SelectorFloatingFilter',
    },
    {
      headerName: 'Memo',
      field: 'memo',
      type: TGridColumnType.EditableColumn,
      cellRendererParams: {
        type: 'memo',
        isEditable: true,
      } as ICustomCellRendererParams,
      colId: 'memo',
      width: 75,
    },
    {
      headerName: 'Current Vehicle',
      field: 'currentVehicle',
      cellRendererParams: {
        type: 'move',
      },
      width: 75,
    },
    {
      headerName: 'Status',
      field: 'status',
      colId: 'status',
      cellClass: 'font-bold',
      cellClassRules: getStatusCellClassRules(),
      cellRenderer: 'CustomCell',
      cellRendererParams: {
        type: 'status',
      } as ICustomCellRendererParams,
      width: 75,
      floatingFilterComponent: 'SelectorFloatingFilter',
      filterParams: selectorFilterParams,
    },
    {
      headerName: 'Invoice Status',
      field: 'invoiceStatus',
      colId: 'invoicingStatus',
      width: 75,
      cellRendererParams: {
        type: 'status',
      } as ICustomCellRendererParams,
      floatingFilterComponent: 'SelectorFloatingFilter',
      filterParams: selectorFilterParams,
      cellRenderer: 'CustomCell',
      valueGetter: params => {
        const status = params?.data?.invoiceStatus;

        if (status === 'VERIFIED') {
          return 'VFED';
        }
        if (status === 'INVOICED') {
          return 'INVC';
        }
        return null;
      },
    },
    {
      headerName: 'Last Vehicle',
      field: 'lastVehicle',
      suppressNavigable: true,
      cellRendererParams: {
        type: 'move',
      },
      width: 75,
    },
    {
      headerName: 'Shift',
      field: 'shift',
      cellClass: getCellClass('shift'),
      cellRendererParams: {
        type: 'shift',
      } as ICustomCellRendererParams,
      floatingFilterComponent: 'SelectorFloatingFilter',
    },
    {
      headerName: 'Docket',
      field: 'docket',
      width: 125,
    },
    {
      headerName: 'Scheduled Priority',
      field: 'schedulePriority',
      width: 75,
      cellEditor: 'NumericCell',
      cellRendererParams: {
        type: 'number',
      } as ICustomCellRendererParams,
      filter: 'agNumberColumnFilter',
      filterParams: numberFilterParams,
      comparator: sortByNumber,
      valueGetter: params => {
        if (params?.data?.schedulePriority === 0) {
          return '0';
        }
        return params?.data?.schedulePriority;
      },
    },
    {
      headerName: 'Division',
      field: 'divisionName',
      width: 90,
    },
    {
      headerName: 'Freight Payer',
      field: 'freightPayerName',
      width: 90,
    },
    {
      headerName: 'Freight Provider',
      field: 'freightProviderName',
      width: 90,
    },
    {
      headerName: 'Booking Type',
      field: 'bookingType',
      width: 60,
      floatingFilterComponent: 'SelectorFloatingFilter',
    },
    {
      headerName: 'Weight',
      field: 'weight',
      filter: 'agNumberColumnFilter',
      width: 75,
      filterParams: numberFilterParams,
      comparator: sortByNumber,
    },
    {
      headerName: 'Weight Actual',
      field: 'weightActual',
      filter: 'agNumberColumnFilter',
      cellRendererParams: {
        type: 'weight',
        precision: 2,
      } as ICustomCellRendererParams,
      width: 75,
      filterParams: numberFilterParams,
      comparator: sortByNumber,
    },
    {
      headerName: 'Pickup',
      field: 'pickupSiteName',
      colId: 'pickupSiteName',
      width: 90,
    },
    {
      headerName: 'Activity',
      field: 'activitySiteName',
      colId: 'activitySiteName',
      width: 90,
    },
    {
      headerName: 'Deliver',
      field: 'deliverySiteName',
      colId: 'deliverySiteName',
      width: 90,
    },
    {
      headerName: 'From Suburb',
      field: 'fromSuburb',
      colId: 'fromSuburb',
      width: 90,
    },

    {
      headerName: 'Activity Suburb',
      field: 'activitySuburb',
      colId: 'activitySuburb',
      width: 90,
    },

    {
      headerName: 'To Suburb',
      field: 'toSuburb',
      colId: 'toSuburb',
      width: 90,
    },

    {
      headerName: 'From Region',
      field: 'fromRegion',
      colId: 'fromRegion',
      width: 90,
    },

    {
      headerName: 'Activity Region',
      field: 'activityRegion',
      colId: 'activityRegion',
      width: 90,
    },

    {
      headerName: 'To Region',
      field: 'toRegion',
      colId: 'toRegion',
      width: 90,
    },

    {
      headerName: 'Booked Date',
      field: 'bookedDate',
      filter: 'agDateColumnFilter',
      cellRendererParams: {
        type: 'datetime',
      } as ICustomCellRendererParams,
      cellRenderer: 'CustomCell',
      width: 180,
      filterParams: dateFilterParams,
    },
    {
      headerName: 'Rate Override',
      field: 'rateOverride',
      cellRenderer: 'CheckedCell',
      width: 100,
      floatingFilterComponent: 'SelectorFloatingFilter',
      filterParams: booleanSelectorFilterParams,
    },
    {
      headerName: 'Is Chargeable',
      field: 'isChargeable',
      cellRenderer: 'CheckedCell',
      width: 100,
      floatingFilterComponent: 'SelectorFloatingFilter',
      filterParams: booleanSelectorFilterParams,
    },
    {
      headerName: 'No Dispatch',
      field: 'noDispatch',
      cellRenderer: 'CheckedCell',
      width: 100,
      floatingFilterComponent: 'SelectorFloatingFilter',
      filterParams: booleanSelectorFilterParams,
    },
    {
      headerName: 'Cost',
      field: 'cost',
      filter: 'agNumberColumnFilter',
      width: 100,
      comparator: sortByNumber,
      cellRendererParams: {
        type: 'currency',
      } as ICustomCellRendererParams,
      cellRenderer: 'CustomCell',
      filterParams: numberFilterParams,
    },
    {
      headerName: 'Cartage Charge',
      field: 'cartageCharge',
      filter: 'agNumberColumnFilter',
      width: 100,
      comparator: sortByNumber,
      cellRendererParams: {
        type: 'currency',
      } as ICustomCellRendererParams,
      cellRenderer: 'CustomCell',
      filterParams: numberFilterParams,
    },
    {
      headerName: 'Surcharge',
      field: 'surcharge',
      filter: 'agNumberColumnFilter',
      width: 100,
      comparator: sortByNumber,
      cellRendererParams: {
        type: 'currency',
      } as ICustomCellRendererParams,
      cellRenderer: 'CustomCell',
      filterParams: numberFilterParams,
    },
    {
      headerName: 'Charge Amount',
      field: 'chargeAmount',
      filter: 'agNumberColumnFilter',
      width: 100,
      comparator: sortByNumber,
      cellRendererParams: {
        type: 'currency',
      } as ICustomCellRendererParams,
      cellRenderer: 'CustomCell',
      filterParams: numberFilterParams,
    },
    {
      headerName: 'FAF Amount',
      field: 'fafAmount',
      filter: 'agNumberColumnFilter',
      width: 100,
      comparator: sortByNumber,
      cellRendererParams: {
        type: 'currency',
      } as ICustomCellRendererParams,
      filterParams: numberFilterParams,
    },
    {
      headerName: 'Total Charge',
      field: 'totalCharge',
      filter: 'agNumberColumnFilter',
      width: 100,
      comparator: sortByNumber,
      cellRendererParams: {
        type: 'currency',
      } as ICustomCellRendererParams,
      cellRenderer: 'CustomCell',
      filterParams: numberFilterParams,
    },
    {
      field: 'orderRef',
      headerName: 'Order Ref',
      width: 100,
    },
    {
      field: 'pickupRef',
      headerName: 'Pickup Ref',
      width: 100,
    },
    {
      field: 'activityRef',
      headerName: 'Activity Ref',
      width: 100,
    },
    {
      field: 'deliveryRef',
      headerName: 'Delivery Ref',
      width: 100,
    },
    {
      field: 'pickupPin',
      headerName: 'Pickup PIN',
      width: 100,
    },
    {
      field: 'activityPin',
      headerName: 'Activity PIN',
      width: 100,
    },
    {
      field: 'deliveryPin',
      headerName: 'Deliver PIN',
      width: 100,
    },
    {
      field: 'pickupVbs',
      headerName: 'Pickup VBS',
      width: 100,
    },

    {
      field: 'activityVbs',
      headerName: 'Activity VBS',
      width: 100,
    },

    {
      field: 'deliveryVbs',
      headerName: 'Deliver VBS',
      width: 100,
    },

    {
      field: 'pickupRequired',
      colId: 'pickupRequired',
      filter: 'agDateColumnFilter',
      headerName: 'Pickup Required',
      cellClassRules: getDateCellClassRules(),
      width: 180,
      cellRendererParams: {
        type: 'datetimeUTCtoLocal',
      } as ICustomCellRendererParams,
      cellRenderer: 'CustomCell',
      filterParams: dateFilterParams,
    },
    {
      field: 'activityRequired',
      colId: 'activityRequired',
      filter: 'agDateColumnFilter',
      headerName: 'Activity Required',
      cellClassRules: getDateCellClassRules(),
      width: 180,
      cellRendererParams: {
        type: 'datetimeUTCtoLocal',
      } as ICustomCellRendererParams,
      cellRenderer: 'CustomCell',
      filterParams: dateFilterParams,
    },
    {
      field: 'deliveryRequired',
      colId: 'deliveryRequired',
      filter: 'agDateColumnFilter',
      headerName: 'Delivery Required',
      cellClassRules: getDateCellClassRules(),
      width: 180,
      cellRendererParams: {
        type: 'datetimeUTCtoLocal',
      } as ICustomCellRendererParams,
      cellRenderer: 'CustomCell',
      filterParams: dateFilterParams,
    },

    {
      field: 'nextMilestone',
      headerName: 'Next Milestone',
      width: 180,
      cellClassRules: getNextMilestoneColorRules(),
      filter: 'agDateColumnFilter',
      filterParams: dateFilterParams,
      valueGetter: params => {
        const nextMilestoneValue = params?.data?.nextMilestoneValue;
        const nextMilestone = params?.data?.nextMilestone;

        if (nextMilestone) {
          const zoneDate = dayjs(nextMilestone).startOf('day');
          const currentDate = dayjs().startOf('day');
          const daysUntilNextMilestone = zoneDate.diff(currentDate, 'day');
          const localTime = dayjs(nextMilestone).format('DD/MM/YYYY');

          return `${localTime} ${nextMilestoneValue} (${Math.round(
            daysUntilNextMilestone,
          )})`;
        }
        return null;
      },
    },

    {
      field: 'pickupActual',
      colId: 'pickupActual',
      filter: 'agDateColumnFilter',
      headerName: 'Pickup Actual',
      cellClassRules: getDateCellClassRules(),
      width: 180,
      cellRendererParams: {
        type: 'datetimeUTCtoLocal',
      } as ICustomCellRendererParams,
      cellRenderer: 'CustomCell',
      filterParams: dateFilterParams,
    },
    {
      field: 'activityActual',
      colId: 'activityActual',
      filter: 'agDateColumnFilter',
      headerName: 'Activity Actual',
      cellClassRules: getDateCellClassRules(),
      width: 180,
      cellRendererParams: {
        type: 'datetimeUTCtoLocal',
      } as ICustomCellRendererParams,
      cellRenderer: 'CustomCell',
      filterParams: dateFilterParams,
    },
    {
      field: 'deliveryActual',
      colId: 'deliveryActual',
      filter: 'agDateColumnFilter',
      headerName: 'Delivery Actual',
      cellClassRules: getDateCellClassRules(),
      width: 180,
      cellRendererParams: {
        type: 'datetimeUTCtoLocal',
      } as ICustomCellRendererParams,
      cellRenderer: 'CustomCell',
      filterParams: dateFilterParams,
    },
    {
      headerName: 'Has DGs',
      field: 'hasDgs',
      cellRenderer: 'CheckedCell',
      width: 100,
      floatingFilterComponent: 'SelectorFloatingFilter',
      filterParams: booleanSelectorFilterParams,
    },

    {
      headerName: 'Now At',
      cellClass: 'text-darkblue-100',
      cellClassRules: getNowAtCellClassRules(),
      field: 'nowAt',
      valueGetter: params => {
        return params?.data?.nowAt === 'On Truck'
          ? params?.data?.nowAt
          : params?.data?.nowAt?.toUpperCase();
      },
      width: 100,
    },
    {
      headerName: 'Going To',
      field: 'goingTo',
      cellClass: 'text-black-100',
      cellClassRules: getGoingToCellClassRules(),
      valueGetter: params => {
        let newVlaue = params?.data?.goingTo;
        if (
          params?.data?.status === 'PLN' ||
          params?.data?.status === 'ALL' ||
          params?.data?.status === 'PUP'
        ) {
          newVlaue = `➜ ${params?.data?.goingTo}`;
        }
        return newVlaue?.toUpperCase();
      },
      width: 100,
    },
    {
      headerName: 'Booked by',
      field: 'bookedBy',
      width: 100,
    },

    {
      field: 'lastChanged',
      headerName: 'Last Changed',
      filter: 'agDateColumnFilter',
      filterParams: dateFilterParams,
      cellRendererParams: {
        type: 'datetime',
      } as ICustomCellRendererParams,
      cellRenderer: 'CustomCell',
      width: 180,
    },
    {
      headerName: 'Now at Suburb',
      field: 'nowAtSuburb',
      width: 100,
    },
    {
      headerName: 'Going to Suburb',
      field: 'goingToSuburb',
      width: 100,
    },
    ...bookingAttributeColDefs,
  ],
} as GridOptions;
