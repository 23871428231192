import { GridOptions } from 'ag-grid-community';
import {
  BreakPointNumericCell,
  InputCell,
  NumericCell,
  SelectRateTypesCell,
  SelectServiceProviderDefault,
  SelectServiceProviderStatus,
} from 'components/ReactGrid/editors';
import { FullRowAction } from 'components/ReactGrid/renderers';
import CustomCell from 'components/ReactGrid/renderers/CustomCell';
import { ICustomCellRendererParams } from 'components/ReactGrid/renderers/types';
import { BreakpointRateType } from 'components/config/types/rates';

export const rateLinesGridConfig = {
  toolbar: {
    actions: [
      {
        id: 'create',
        label: 'Add',
        tooltip: 'Add new item',
        skipPermission: true,
      },
      {
        id: 'delete',
        tooltip: 'Delete selected records',
        skipPermission: true,
      },
    ],
  },
  gridId: 'rateLine',
  gridHeight: '45vh',
  gridClassName: 'grid-container',
  gridOptions: {
    rowSelection: 'multiple',
    suppressContextMenu: true,
    enablePivot: true,
    components: {
      CustomCell,
      FullRowAction,
      InputCell,
      NumericCell,
      SelectServiceProviderStatus,
      SelectServiceProviderDefault,
      SelectRateTypesCell,
      BreakPointNumericCell,
    },
    // singleClickEdit: true,

    maintainColumnOrder: true,
    editType: 'fullRow',
    defaultColDef: {
      flex: 1,
      resizable: true,
      sortable: true,
      // suppressMenu: true,
      cellRenderer: 'CustomCell',
      gridId: 'rateLine',
      editable: true,
    },
    columnDefs: [
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id',
        pinned: 'left',
        width: 80,
        editable: false,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        showDisabledCheckboxes: true,
      },
      {
        headerName: 'Break Point 1',
        field: 'breakPoint1',
        width: 100,
        cellEditor: 'BreakPointNumericCell',
        valueGetter: params => {
          return params?.data?.breakPoint1 === 999999
            ? 'ALL ELSE'
            : `Upto (=<) ${params?.data?.breakPoint1}`;
        },
        comparator: (valueA, valueB) => {
          let result;
          try {
            const formattedValue = input => {
              if (input === 'ALL ELSE') {
                return 999999;
              }
              const inputList = input.split(' ');
              return Number(inputList[inputList?.length - 1]);
            };

            if (formattedValue(valueA) === formattedValue(valueB)) return 0;
            result = formattedValue(valueA) > formattedValue(valueB) ? 1 : -1;
          } catch (err) {
            console.warn(err);
          }
          return result;
        },
      },
      {
        headerName: 'Break Point 2',
        field: 'breakPoint2',
        width: 100,
        editable: false,
      },
      {
        headerName: 'Rate Type',
        field: 'rateType',
        width: 50,
        cellEditor: 'SelectRateTypesCell',
        valueGetter: params => {
          const selectedItem = BreakpointRateType?.find(
            item => item?.value === params?.data?.rateType,
          );
          return selectedItem?.label;
        },
      },
      {
        headerName: 'Charge',
        field: 'charge',
        width: 50,
        cellEditor: 'NumericCell',
        cellRendererParams: {
          type: 'currency',
        } as ICustomCellRendererParams,
        valueGetter: params => {
          return params?.data?.charge?.toFixed(2);
        },
      },
      {
        headerName: 'Cost',
        field: 'cost',
        width: 50,
        cellEditor: 'NumericCell',
        cellRendererParams: {
          type: 'currency',
        } as ICustomCellRendererParams,
        valueGetter: params => {
          return params?.data?.cost?.toFixed(2);
        },
      },
      {
        headerName: 'Action',
        colId: 'action',
        cellRenderer: 'FullRowAction',
        cellClass: 'cellCenter',
        editable: false,
        filter: false,
        width: 70,
        pinned: 'right',
      },
    ],
  } as GridOptions,
};
