import {
  OrganizationOrganizationMappingFilterInput,
  Rate,
} from 'api/graphql/generated/serviceTypesAndHooks';
import { OrganizationType } from 'components/config/types/common';
import { ModuleTypes } from 'components/config/types/modules';
import RateForm from 'containers/Financial/Rates/DetailsScreen/RateForm';
import RateLineForm from 'containers/Financial/Rates/DetailsScreen/RateLineForm';
import MainScreen from 'containers/Financial/Rates/MainScreen';
import useApp from 'context/app/hooks';
import { actions as rateActions } from 'context/financials_rate/slice';
import useAuthentication from 'context/security_authentication/hook';
import { actions, reducer, sliceKey } from 'context/workspace/slice';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useInjectReducer } from 'utils/redux-injectors';

export default () => {
  useInjectReducer({
    key: sliceKey,
    reducer,
  });

  const dispatch = useDispatch();

  const { currentAccount } = useAuthentication();
  const { openDialog, closeDialog } = useApp();

  const onRateUpdate = (response: Rate) => {
    closeDialog();
    window.$gridApi.getRowNode?.(response?.id)?.setData?.(response);
  };

  function _createNewRate() {
    openDialog({
      content: <RateForm afterValidation={onRateUpdate} />,
      dialogProps: {
        closable: false,
        height: '623px',
        width: '860px',
      },
    });
  }

  function _createNewRateLine() {
    openDialog({
      content: <RateLineForm afterValidation={onRateUpdate} />,
      dialogProps: {
        closable: false,
        height: '423px',
        width: '860px',
      },
    });
  }

  dispatch(
    actions.setWorkspace({
      loading: true,
      workspace: {
        title: 'Rate',
        id: 'rate-list',

        module: ModuleTypes.Rate,
      },
    }),
  );

  useEffect(() => {
    dispatch(
      rateActions.setQueryOrgList({
        query: {
          cursor: null,
          filters: {
            parentOrganizationId: {
              eq:
                currentAccount?.organizationOrganizationMapping
                  ?.parentOrganizationId,
            },
            organizationTypeId: { eq: OrganizationType.Customer },
            isDeleted: { neq: true },
          } as OrganizationOrganizationMappingFilterInput,
          order: { id: 'DESC' },
        },
      }),
    );
  }, []);

  return (
    <MainScreen
      onCreateNewRate={_createNewRate}
      onCreateNewRateLine={_createNewRateLine}
    />
  );
};
