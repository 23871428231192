import tw, { styled } from 'twin.macro';

export const StyledFormGroup = styled.div`
  h4 {
    display: block;
    width: 100%;
  }

  .ant-btn-text {
    background-color: ${p => p.theme.primaryButtonColor};
    color: white;
  }

  .grid-container {
    min-height: ${props => props?.style?.minHeight};
    ${tw`relative`}
    @media screen and (max-width: 1920px) {
      height: calc(100vh - 730px);
    }
    @media screen and (max-width: 1536px) {
      height: calc(100vh - 618px);
    }
    @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
      height: calc(100vh - 770px);
    }

    div[class*='ant-spin'] {
      height: inherit;
    }
    .ag-row .ag-cell {
      display: flex;
      align-items: center;
    }
  }

  .small-grid-container {
    ${tw`relative`}
    @media screen and (max-width: 1920px) {
      height: calc(100vh - 860px);
    }
    @media screen and (max-width: 1536px) {
      height: calc(100vh - 850px);
    }
    @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
      height: calc(100vh - 750px);
    }

    div[class*='ant-spin'] {
      height: inherit;
    }
    .ag-row .ag-cell {
      display: flex;
      align-items: center;
    }
  }
`;
