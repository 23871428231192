import { CellClickedEvent, GridApi, GridReadyEvent } from 'ag-grid-community';
import { message } from 'antd';
import TopWidgets from 'components/Layout/TopWidgets';
import AGGrid from 'components/ReactGrid';
import useRates from 'context/financials_rate/hooks';
import {
  selectRateLines,
  selectSelectedRate,
} from 'context/financials_rate/slice';

import { generateId } from 'helpers/generator';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { rateLinesGridConfig } from '../rateLinesGridConfig';
import { StyledFormGroup } from './styles';

export interface Props {
  label?: string;
  style?: {};
  gridHeight?: any;
  selectedMenuItem: any;

  onSelectionChanged?: (selectedRow: any) => void;
  onCreateNew?: () => void;
}

export default function RateGrid(props: Readonly<Props>) {
  const { onSelectionChanged } = props;
  const { rateLineList, modifyRate, createRate, copyRateLine } = useRates();
  const [gridApi, setGridApi] = useState(null as GridApi);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [dataItems, setDataItems] = useState([]);
  const selectedRate = useSelector(selectSelectedRate);
  const currentRateLines = useSelector(selectRateLines);

  // =================== Handlers ===========================//

  const handleSetItems = list => {
    if (list?.length) {
      const newList = [...list];
      const newMapList = newList?.map(item => {
        const tempId = generateId(false, 6);
        return { tempId, ...item };
      });
      setDataItems(newMapList);
    } else {
      setDataItems([]);
    }
  };

  const onGridReady = ({ api, columnApi }: GridReadyEvent) => {
    api.setHeaderHeight(36);
    setGridApi(api);
    setGridColumnApi(columnApi);
  };

  const handleAddNewItem = () => {
    if (props.onCreateNew) {
      props.onCreateNew();
    }
  };

  const buildRateData = selectedRate => ({
    organizationId: selectedRate?.organizationId,
    startDate: selectedRate?.startDate,
    endDate: selectedRate?.endDate,
    serviceTypeId: selectedRate?.serviceTypeId,
    tonne: selectedRate?.tonne || 0,
    minimum: selectedRate?.minimum || 0,
    maximum: selectedRate?.maximum || 0,
    rd: selectedRate?.rd || 0,
    rateCommodityId: selectedRate?.rateCommodityId,
    detail: selectedRate?.detail,
    unitOfMeasureId: selectedRate?.unitOfMeasureId,
    addressFromId: selectedRate?.addressFromId,
    addressToId: selectedRate?.addressToId,
  });

  const updateRateLine = async input => {
    const updateRateData = {
      id: input?.selectedRate?.id,
      endDate: new Date().toJSON(),
      isDeleted: true,
    };

    const prepareRateLineData = (rateId, input) =>
      input?.currentRateLines?.nodes?.map(rateLine => ({
        breakPoint1:
          rateLine?.id === input?.id
            ? input?.breakPoint1 || 0
            : rateLine?.breakPoint1 || 0,
        charge:
          rateLine?.id === input?.id
            ? input?.charge || 0
            : rateLine?.charge || 0,
        cost:
          rateLine?.id === input?.id ? input?.cost || 0 : rateLine?.cost || 0,
        rateType:
          rateLine?.id === input?.id ? input?.rateType : rateLine?.rateType,
        rateId,
      }));

    const afterUpdate = async (input, rateData) => {
      const resultCreateRate = await createRate(rateData);

      if (resultCreateRate?.data?.addRate) {
        const rateLineData = prepareRateLineData(
          resultCreateRate.data.addRate.rate.id,
          input,
        );
        const resultRateLine = await copyRateLine(rateLineData);
        if (resultRateLine?.data) {
          return {
            data: resultRateLine.data,
            message: 'Rate line updated successfully',
          };
        }
      }
    };

    const startUpdate = async input => {
      const resultModifyRate = await modifyRate(updateRateData);

      if (resultModifyRate?.data?.modifyRate) {
        const rateData = buildRateData(input.selectedRate);
        return afterUpdate(input, rateData);
      }

      return {
        message: 'Failed to update rate line',
      };
    };
    return startUpdate(input);
  };

  const handleConfirmDelete = async param => {
    const { data } = param;
    if (currentRateLines?.nodes?.length < 2) {
      message.error('Rate should have at least one rate line');
      return;
    }

    const input = {
      deleteRateLines: [data?.id],
      currentRateLines,
      selectedRate,
    };
    const result = await deleteRateLines(input);

    if (result?.data) {
      message.success(result?.message);
      gridApi.applyTransaction({ remove: [data] });
    } else {
      message.error(result?.message);
    }
  };

  const deleteRateLines = async input => {
    const updateRateData = {
      id: input?.selectedRate?.id,
      endDate: new Date().toJSON(),
      isDeleted: true,
    };

    const prepareRateLines = async (
      rateId,
      currentRateLines,
      deleteRateLines,
    ) => {
      const filteredRateLines = currentRateLines?.nodes?.filter(
        rateLine => !deleteRateLines.includes(rateLine?.id),
      );

      const rateLineData = filteredRateLines.map(rateLine => ({
        breakPoint1: rateLine?.breakPoint1 || 0,
        charge: rateLine?.charge || 0,
        cost: rateLine?.cost || 0,
        rateType: rateLine?.rateType,
        rateId,
      }));

      const resultRateLine = await copyRateLine(rateLineData);
      return resultRateLine?.data
        ? {
            data: resultRateLine.data,
            message: 'Delete Rate lines successfully',
          }
        : { message: 'Canot delete rate lines' };
    };

    const createNewRate = async rateData => {
      const resultCreateRate = await createRate(rateData);
      if (resultCreateRate?.data?.addRate) {
        return prepareRateLines(
          resultCreateRate.data.addRate.rate.id,
          input.currentRateLines,
          input.deleteRateLines,
        );
      }
      return { message: 'Canot delete rate lines' };
    };

    const startDelete = async () => {
      const resultModifyRate = await modifyRate(updateRateData);
      if (resultModifyRate?.data?.modifyRate) {
        const rateData = buildRateData(input.selectedRate);
        return createNewRate(rateData);
      }
      return { message: 'Failed to update rate' };
    };

    return startDelete();
  };

  const handleMultipleDelete = async () => {
    const selectedRows = gridApi?.getSelectedRows();
    const selectedRowIds = selectedRows?.map(el => el?.id);

    if (currentRateLines?.nodes?.length - selectedRowIds?.length < 1) {
      message.error('Rate should have at least one rate line');
      return;
    }

    const input = {
      deleteRateLines: selectedRowIds,
      currentRateLines,
      selectedRate,
    };
    const result = await deleteRateLines(input);

    if (result?.data) {
      message.success(result?.message);
      gridApi.applyTransaction({ remove: selectedRows });
    } else {
      message.error(result?.message);
    }
  };

  const handleSelectionChanged = e => {
    const record = e?.api?.getSelectedNodes()[0];
    const selectedRow = { gridId: record?.gridId, ...record?.data };
    selectedRow.gridId = rateLinesGridConfig?.gridId;

    onSelectionChanged(selectedRow);
  };

  const refreshCells = params => {
    params.api.refreshCells({
      columns: ['action'],
      rowNodes: [params.node],
      force: true,
    });
  };

  const handleCellClicked = async (params: CellClickedEvent) => {
    const { node, api, event, column } = params;
    // Handle click event for action cells
    if (
      column.getColId() === 'action' &&
      (event.target as Element).closest('[id]')?.id
    ) {
      const action = (event.target as Element).closest('[id]')?.id;

      if (action === 'edit') {
        api.startEditingCell({
          rowIndex: node.rowIndex,
          // gets the first columnKey
          colKey: 'breakPoint1',
        });
      }

      if (action === 'update') {
        api.stopEditing();
      }

      if (action === 'cancel') {
        api.stopEditing();
        handleSetItems(rateLineList?.nodes);
      }
    } else {
      handleSelectionChanged(params);
    }
  };

  const handleCofirmUpdate = async params => {
    const { node, api } = params;
    const data = node.data;

    const newData = {
      ...data,
      currentRateLines,
      selectedRate,
    };

    const result: any = await updateRateLine(newData);

    if (result?.data) {
      // remove the item from aggrid
      const nodes = api?.getRenderedNodes();
      const isSeleted = nodes?.length > 0;
      nodes[0]?.setSelected(isSeleted); // selects the first row in the rendered view

      message.success(result?.message);
    } else {
      message.error(result?.message);
    }
  };

  // =================== UseEffects ===========================//

  useEffect(() => {
    handleSetItems(rateLineList?.nodes);
  }, [rateLineList?.nodes]);

  // =================== Render components ===========================//
  return (
    <StyledFormGroup>
      <TopWidgets
        toolbar={rateLinesGridConfig?.toolbar}
        onCreateNew={handleAddNewItem}
        onMultipleDelete={handleMultipleDelete}
        className="grid-toolbar-details"
      />
      <h4>{props.label}</h4>
      <div
        className={rateLinesGridConfig?.gridClassName}
        style={{
          height: `${rateLinesGridConfig?.gridHeight}`,
        }}
      >
        <AGGrid
          {...rateLinesGridConfig.gridOptions}
          context={{
            onConfirmDelete: handleConfirmDelete,
            onConfirmUpdate: handleCofirmUpdate,
          }}
          suppressClickEdit={true}
          gridApi={gridApi}
          columnApi={gridColumnApi}
          onGridReady={onGridReady}
          onRowEditingStarted={refreshCells}
          onRowEditingStopped={refreshCells}
          rowData={dataItems}
          hideOverlay
          enableCellTextSelection={false}
          onCellClicked={handleCellClicked}
        />
      </div>
    </StyledFormGroup>
  );
}
