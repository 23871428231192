import { CellClickedEvent, GridApi, GridReadyEvent } from 'ag-grid-community';
import { message } from 'antd';
import TopWidgets from 'components/Layout/TopWidgets';
import AGGrid from 'components/ReactGrid';
import useRates from 'context/financials_rate/hooks';
import {
  selectRateLines,
  selectSelectedRate,
} from 'context/financials_rate/slice';
import { generateId } from 'helpers/generator';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ratesGridConfig } from '../rateGridConfig';
import { StyledFormGroup } from './styles';

export interface Props {
  label?: string;
  style?: {};
  gridHeight?: any;
  selectedMenuItem: any;
  onSelectionChanged?: (selectedRow: any) => void;
  onCreateNew?: () => void;
}

export default function RateGrid(props: Readonly<Props>) {
  const { onSelectionChanged } = props;
  const {
    rateList,
    modifyRate,
    deleteRates,
    createRate,
    copyRateLine,
  } = useRates();
  const [gridApi, setGridApi] = useState(null as GridApi);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [dataItems, setDataItems] = useState([]);

  const selectedRate = useSelector(selectSelectedRate);
  const currentRateLines = useSelector(selectRateLines);

  // =================== Handlers ===========================//

  const handleSetItems = list => {
    if (list?.length) {
      const newList = [...list];
      const newMapList = newList?.map(item => {
        const tempId = generateId(false, 6);
        return { tempId, ...item };
      });
      setDataItems(newMapList);
    } else {
      setDataItems([]);
    }
  };

  const onGridReady = ({ api, columnApi }: GridReadyEvent) => {
    api.setHeaderHeight(36);
    setGridApi(api);
    setGridColumnApi(columnApi);
  };

  const handleAddNewItem = () => {
    if (props.onCreateNew) {
      props.onCreateNew();
    }
  };

  const updateRate = async input => {
    const updateRateData = {
      id: input?.id,
      endDate: new Date().toJSON(),
      isDeleted: true,
    };

    const createRateLines = async (rateId, currentRateLines) => {
      const rateLineData = currentRateLines?.nodes?.map(rateLine => ({
        breakPoint1: rateLine?.breakPoint1 || 0,
        charge: rateLine?.charge || 0,
        cost: rateLine?.cost || 0,
        rateType: rateLine?.rateType,
        rateId,
      }));

      const resultRateLine = await copyRateLine(rateLineData);
      return resultRateLine?.data
        ? { data: resultRateLine?.data, message: 'Create Rate successfully' }
        : { message: 'Cannot create Rate Line' };
    };

    const createNewRate = async (input, newRateData) => {
      const resultCreateRate = await createRate(newRateData);
      if (resultCreateRate?.data?.addRate) {
        return createRateLines(
          resultCreateRate.data.addRate.rate.id,
          input?.currentRateLines,
        );
      }
      return { message: 'Cannot create new Rate' };
    };

    const getRateData = input => {
      const hasNewAddressFrom = input?.editedAdressFrom?.mapId;
      const hasNewAddressTo = input?.editedAddressTo?.mapId;

      return {
        organizationId: input?.organizationId,
        startDate: input?.startDate,
        endDate: input?.endDate,
        serviceTypeId: input?.serviceTypeId,
        tonne: input?.tonne || 0,
        minimum: input?.minimum || 0,
        maximum: input?.maximum || 0,
        rd: input?.rd || 0,
        rateCommodityId: input?.rateCommodityId,
        detail: input?.detail,
        unitOfMeasureId: input?.unitOfMeasureId,
        addressFromInput: hasNewAddressFrom
          ? input?.editedAdressFrom
          : undefined,
        addressToInput: hasNewAddressTo ? input?.editedAddressTo : undefined,
        addressFromId: hasNewAddressFrom ? undefined : input?.addressFromId,
        addressToId: hasNewAddressTo ? undefined : input?.addressToId,
      };
    };

    const startUpdate = async () => {
      const resultModifyRate = await modifyRate(updateRateData);
      if (resultModifyRate?.data?.modifyRate) {
        const rateData = getRateData(input);
        return createNewRate(input, rateData);
      }
      return { message: 'Cannot update Rate' };
    };

    return startUpdate();
  };

  const deleteRate = async ids => {
    const resultRate = await deleteRates(ids);
    if (resultRate?.data?.deleteRates) {
      return {
        data: resultRate?.data?.deleteRates,
        message: 'Delete Rate(s) successfully',
      };
    }
    return {
      message: 'Cannot delete Rate(s)',
    };
  };

  const handleConfirmDelete = async param => {
    const { api, data } = param;

    const result = await deleteRate([data?.id]);
    if (result?.data) {
      message.success(result?.message);
      api.applyTransaction({ remove: [data] });
    } else {
      message.error(result?.message);
    }
  };

  const handleMultipleDelete = async () => {
    const selectedRows = gridApi?.getSelectedRows();
    const selectedRowIds = selectedRows?.map(el => el?.id);

    const result = await deleteRate(selectedRowIds);
    if (result?.data) {
      message.success(result?.message);
      gridApi.applyTransaction({ remove: selectedRows });
    } else {
      message.error(result?.message);
    }
  };

  const handleSelectionChanged = e => {
    const record = e?.api?.getSelectedNodes()[0];
    const selectedRow = { gridId: record?.gridId, ...record?.data };
    selectedRow.gridId = ratesGridConfig?.gridId;

    onSelectionChanged(selectedRow);
  };

  const refreshCells = params => {
    params.api.refreshCells({
      columns: ['action'],
      rowNodes: [params.node],
      force: true,
    });
  };

  const handleCellClicked = async (params: CellClickedEvent) => {
    handleSelectionChanged(params);
    const { node, api, event, column } = params;
    // Handle click event for action cells
    if (
      column.getColId() === 'action' &&
      (event.target as Element).closest('[id]')?.id
    ) {
      const action = (event.target as Element).closest('[id]')?.id;

      if (action === 'edit') {
        api.startEditingCell({
          rowIndex: node.rowIndex,
          // gets the first columnKey
          colKey: 'addressFrom',
        });
      }

      if (action === 'update') {
        api.stopEditing();
      }

      if (action === 'cancel') {
        api.stopEditing();
        handleSetItems(rateList?.nodes);
      }
    }
  };

  const handleCofirmUpdate = async params => {
    const { node, api } = params;
    const data = node.data;

    const newData = {
      ...data,
      editedAdressFrom: data.addressFrom.id == null ? data.addressFrom : null,
      editedAddressTo: data.addressTo.id == null ? data.addressTo : null,
      currentRateLines,
      selectedRate,
    };

    const result: any = await updateRate(newData);

    if (result?.data) {
      // remove the item from aggrid
      const nodes = api?.getRenderedNodes();
      const isSeleted = nodes?.length > 0;
      nodes[0]?.setSelected(isSeleted); // selects the first row in the rendered view
      message.success(result?.message);
    } else {
      message.error(result?.message);
    }
  };

  // =================== UseEffects ===========================//

  useEffect(() => {
    handleSetItems(rateList?.nodes);
  }, [rateList?.nodes]);

  // =================== Render components ===========================//
  return (
    <StyledFormGroup>
      <TopWidgets
        toolbar={ratesGridConfig?.toolbar}
        onCreateNew={handleAddNewItem}
        onMultipleDelete={handleMultipleDelete}
        className="grid-toolbar-details"
      />
      <h4>{props.label}</h4>
      <div
        className={ratesGridConfig?.gridClassName}
        style={{
          height: `${ratesGridConfig?.gridHeight}`,
        }}
      >
        <AGGrid
          {...ratesGridConfig.gridOptions}
          context={{
            onConfirmDelete: handleConfirmDelete,
            onConfirmUpdate: handleCofirmUpdate,
          }}
          suppressClickEdit={true}
          gridApi={gridApi}
          columnApi={gridColumnApi}
          onGridReady={onGridReady}
          onRowEditingStarted={refreshCells}
          onRowEditingStopped={refreshCells}
          rowData={dataItems}
          hideOverlay
          enableCellTextSelection={false}
          onCellClicked={handleCellClicked}
        />
      </div>
    </StyledFormGroup>
  );
}
