import React from 'react';

function getMemoPrimaryColor({
  property,
  value,
}: {
  property: 'bg' | 'text';
  value: string;
}) {
  const colors = {
    bg: {
      urgent: 'bg-memo-urgent',
      today: 'bg-memo-today',
      tomorrow: 'bg-memo-tomorrow',
    },
    text: {
      urgent: 'text-memo-urgent',
      today: 'text-memo-today',
      tomorrow: 'text-memo-tomorrow',
    },
  };

  return colors[property][value] || colors[property]['default'];
}

// Memoizing MemoCell to avoid re-rendering if the value prop hasn't changed
const MemoCell = React.memo(({ value }: Readonly<{ value: string }>) => {
  const bgColorClass = getMemoPrimaryColor({
    property: 'bg',
    value: value.toLowerCase(),
  });
  const textColorClass = getMemoPrimaryColor({
    property: 'text',
    value: value.toLowerCase(),
  });

  return (
    <div className="flex items-center my-1 relative">
      <div
        className={`flex w-full items-center px-1 rounded-md bg-opacity-[.15] ${bgColorClass}`}
      >
        <span className={`text-sm ${textColorClass}`}>{value}</span>
      </div>
    </div>
  );
});

export default MemoCell;
