import React from 'react';
import { styled } from 'twin.macro';
import MemoCell from './MemoCell';
import ShiftCell from './ShiftCell';
import StatusCell from './StatusCell';
import { composeCellStyles, formatValue } from './styleFormatter';
import { ICustomCellRenderer } from './types';

const StyledCell = styled.div`
  height: 28px;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
`;

// Memoizing CellTypeFormatter to avoid re-rendering if props haven't changed
const CellTypeFormatter = React.memo((props: ICustomCellRenderer) => {
  let { value } = props;
  const { type, precision, justify } = props;

  if (!value) {
    return null;
  }

  if (typeof value === 'object') {
    value = value.name;
  }

  if (type === 'status') {
    return <StatusCell label={value} />;
  }

  if (type === 'memo') {
    return <MemoCell value={value} />;
  }

  if (type === 'shift') {
    return <ShiftCell value={value} />;
  }

  return (
    <div className="flex w-full items-center">
      <div
        className={`truncate w-full ${composeCellStyles(type, value, justify)}`}
      >
        {type === 'currency' && <span className="text-black-200">$</span>}
        {formatValue(type, value, precision)}
      </div>
    </div>
  );
});

// Memoizing CustomCell
const CustomCell = React.memo((props: ICustomCellRenderer) => {
  const { context, isEditable, isClickable } = props;
  const editable = isEditable && !context.isCustomer;
  const clickable = isClickable && !context.isCustomer;

  return (
    <StyledCell
      className={`flex flex-row items-center w-full px2 ${
        clickable ? 'cursor-pointer' : ''
      } ${editable ? 'cursor-text' : ''}`}
    >
      <CellTypeFormatter {...props} />
    </StyledCell>
  );
});

export default CustomCell;
