import {
  faCancel,
  faPencil,
  faSave,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ICellRendererParams } from 'ag-grid-community';
import { Button, Popconfirm } from 'antd';
import React from 'react';
import tw, { styled } from 'twin.macro';

export interface Props {
  disabled?: boolean;
  context?: any;
}

const StyledWrapper = styled.div`
  ${tw`flex w-full relative text-center items-center justify-center space-x-1 px-1`}
  .anticon {
    vertical-align: baseline;
  }
`;

export default (props: Props & ICellRendererParams) => {
  const { disabled, context } = props;
  const [isEditing, setIsEditing] = React.useState(false);

  const handleConfirmUpdate = () => {
    context.onConfirmUpdate(props);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  const confirmDelete = () => {
    context.onConfirmDelete(props);
  };

  return (
    <StyledWrapper>
      {isEditing ? (
        <>
          <Popconfirm
            className="flex"
            placement="leftBottom"
            title="Are you sure you want to save this change?"
            onConfirm={handleConfirmUpdate}
            okText="Yes"
            cancelText="No"
            disabled={disabled}
          >
            <Button
              size="small"
              disabled={disabled}
              className="flex justify-center items-center"
              id="update"
              icon={
                <FontAwesomeIcon
                  icon={faSave}
                  style={{ color: 'blue', cursor: 'pointer' }}
                />
              }
            />
          </Popconfirm>
          <Button
            size="small"
            disabled={disabled}
            className="flex justify-center items-center"
            formAction="cancel"
            id="cancel"
            onClick={handleCancelEdit}
            icon={
              <FontAwesomeIcon
                icon={faCancel}
                style={{ color: 'red', cursor: 'pointer' }}
              />
            }
          />
        </>
      ) : (
        <>
          <Button
            size="small"
            disabled={disabled}
            className="flex justify-center items-center"
            onClick={handleEdit}
            id="edit"
            icon={
              <FontAwesomeIcon
                icon={faPencil}
                style={{ color: 'orange', cursor: 'pointer' }}
              />
            }
          />
          <Popconfirm
            className="flex items-center"
            placement="leftBottom"
            title="Are you sure you want to delete this record?"
            onConfirm={confirmDelete}
            okText="Yes"
            cancelText="No"
            disabled={disabled}
          >
            <Button
              size="small"
              disabled={disabled}
              className="flex justify-center"
              id="delete"
              icon={
                <FontAwesomeIcon
                  icon={faTrash}
                  style={{ color: 'red', cursor: 'pointer' }}
                />
              }
            />
          </Popconfirm>
        </>
      )}
    </StyledWrapper>
  );
};
