import { ICellEditorParams } from 'ag-grid-community';
import { Select } from 'antd';
import {
  CountryCodeType,
  useGetMapAddressesLazyQuery,
} from 'api/graphql/generated/serviceTypesAndHooks';
import { LatitudAndLongitudValidator } from 'helpers/LatitudAndLongitudValidator';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

interface ISelectCellParams extends ICellEditorParams {
  value: string;
}

export default forwardRef((props: ISelectCellParams, ref) => {
  // = ============================= init ===========================//
  const [value, setValue] = useState(props.value);
  const [options, setOptions] = useState([]);

  const [placeSearch, setPlaceSearch] = useState(null);
  const [suggestList, setSuggestList] = useState(null);

  const typingTimeoutRef = useRef(null);

  // = ============================= hanlder ===========================//

  const handleSearch = value => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(() => {
      setPlaceSearch(value);
    }, 300);
  };

  const refineAddressData = data => {
    const selectedLocality =
      data?.placeType === 'locality' ? data?.text : data?.locality;
    const selectedPostCode =
      data?.placeType === 'postcode' ? data?.text : data?.postCode;
    const selectedPlace =
      data?.placeType === 'place' ? data?.text : data?.place;
    const selectedCountry =
      data?.placeType === 'country' ? data?.text : data?.country;

    return {
      address1: data?.address,
      postCode: selectedPostCode,
      region: data?.region,
      regionCode: data?.regionCode,
      mapId: data?.mapId,
      coordinates: data?.coordinates,
      longitude: LatitudAndLongitudValidator('longitude', data?.longitude),
      latitude: LatitudAndLongitudValidator('latitude', data?.latitude),
      locality: selectedLocality,
      country: selectedCountry,
      countryCode: data?.countryCode,
      place: selectedPlace,
      placeType: data?.placeType,
      neighborhood: data?.neighborhood,
      district: data?.district,
    };
  };

  const handleSelectPlaceChange = value => {
    if (value) {
      const selectedItem = suggestList?.find(item => item?.mapId === value);

      setValue(value);
      const populateFields = refineAddressData(selectedItem);
      if (populateFields) {
        props.parseValue(populateFields);
      }
    }
  };

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        const valueFormat = suggestList?.find(item => item.mapId === value);
        if (valueFormat) {
          return refineAddressData(valueFormat);
        }
        return props.value;
      },
      afterGuiAttached: () => {
        setValue(value);
      },
    };
  });

  const handleClear = () => {
    setValue(props.value);
  };

  // = ============================= data processing ===========================//

  const [
    getMapAddressQuery,
    { loading: pendingAddress },
  ] = useGetMapAddressesLazyQuery({
    onCompleted: response => {
      if (response?.mapAddress?.nodes) {
        const nodes = response.mapAddress.nodes.map(dataItem => {
          if (dataItem.mapId) {
            return dataItem;
          }
          return undefined;
        });
        const filterList = [
          'place',
          'postcode',
          'locality',
          'country',
          'region',
        ];
        const filteredNodes = nodes?.filter(item =>
          filterList.find(el => el === item?.placeType),
        );
        const placeOptions = filteredNodes?.map(placeItem => {
          return {
            value: placeItem?.mapId,
            label: placeItem?.placeName,
          };
        });
        setOptions(placeOptions);
        setSuggestList(filteredNodes);
      }
    },
  });

  // = ============================= useEffect ===========================//

  useEffect(() => {
    if (placeSearch?.length > 0) {
      getMapAddressQuery({
        variables: {
          input: placeSearch,
          countryCode: CountryCodeType.Nz,
          filters: null,
        },
      });
    }
  }, [placeSearch]);

  // = ============================= render components ===========================//

  const renderSearch = () => {
    return (
      <Select
        style={{ width: '100%' }}
        value={value}
        onClear={handleClear}
        onSearch={handleSearch}
        onChange={handleSelectPlaceChange}
        showSearch
        allowClear
        options={options}
        loading={pendingAddress}
        defaultActiveFirstOption={false}
        placeholder={'search address'}
        filterOption={(_input, _option) => true}
        notFoundContent={null}
      />
    );
  };

  // = ============================= main render ===========================//

  return <>{renderSearch()}</>;
});
