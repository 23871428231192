import { Empty, Layout, Typography } from 'antd';
import {
  RateFilterInput,
  RateLineFilterInput,
} from 'api/graphql/generated/serviceTypesAndHooks';
import { RateMenuType } from 'components/config/types/rates';
import RateGrid from 'containers/Financial/Rates/MainScreen/Components/RateGrid';
import useRates from 'context/financials_rate/hooks';
import {
  actions,
  selectBookingRates,
  selectSelectedRate,
} from 'context/financials_rate/slice';
import useAuthentication from 'context/security_authentication/hook';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomerList from './Components/CustomerList';
import RateLineGrid from './Components/RateLineGrid';

type Props = Readonly<{
  onCreateNewRate?: () => void;
  onCreateNewRateLine?: () => void;
  onViewRecord?: (record: any) => void;
}>;

const { Title } = Typography;

export default (props: Props) => {
  // = =========initial===========//
  const { getOrgList, getRates, getRateLines } = useRates();
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);

  const selectedRateIdRef = useRef(null);
  const { currentAccount } = useAuthentication();

  const { queryOrgList, orgList } = useSelector(selectBookingRates);

  const selectedRate = useSelector(selectSelectedRate);
  const dispatch = useDispatch();

  const { Content, Sider } = Layout;

  // ========= event handlers=============//

  const handleSelectionChanged = selectedRow => {
    // set selectedRate on redux store
    if (selectedRow?.gridId === 'rate') {
      dispatch(
        actions.setSelectedRate({
          selectedRow,
        }),
      );
    }

    dispatch(
      actions.setRefreshEditingAddress({
        editingAddressFrom: {},
        editingAddressTo: {},
      }),
    );
  };

  const menuClickHandler = async e => {
    const id = Number(e?.key);
    setSelectedMenuItem({
      type: RateMenuType.CUSTOMER,
      id,
    });
    dispatch(
      actions.setSelectedMenu({
        selectedMenu: {
          type: RateMenuType.CUSTOMER,
          id,
        },
      }),
    );
    dispatch(
      actions.setRefreshData({
        rates: {},
        rateLines: {},
        selectedRow: {},
        editingAddressFrom: {},
        editingAddressTo: {},
      }),
    );
    selectedRateIdRef.current = null;
  };

  // ============ useEffects ============//

  useEffect(() => {
    if (queryOrgList) getOrgList(queryOrgList);
  }, [queryOrgList]);

  useEffect(() => {
    if (selectedMenuItem) {
      getRates({
        cursor: null,
        filters: {
          organizationId: { eq: selectedMenuItem?.id },
          isDeleted: { neq: true },
        } as RateFilterInput,
        order: { id: 'DESC' },
        organizationIds: [selectedMenuItem?.id],
      });
    }
  }, [selectedMenuItem]);

  useEffect(() => {
    // query rateLines
    if (selectedRateIdRef.current !== selectedRate?.id && selectedRate?.id) {
      getRateLines({
        cursor: null,
        filters: {
          rateId: { eq: selectedRate?.id },
          isDeleted: { neq: true },
        } as RateLineFilterInput,
        order: { breakPoint1: 'ASC' },
      });
      selectedRateIdRef.current = selectedRate?.id;
    }
  }, [selectedRate]);

  // = =========== render components ============== //

  return (
    <Layout>
      <Sider width={320} theme="light">
        <CustomerList
          height="calc(31vh)"
          onChange={menuClickHandler}
          customerList={orgList}
          title="Customer List"
          currentAccount={currentAccount}
          selectedMenuItem={selectedMenuItem}
        />
      </Sider>
      <Content>
        {selectedMenuItem ? (
          <div>
            <div className="px-2">
              <Title level={4}>Rates</Title>
              <RateGrid
                onSelectionChanged={handleSelectionChanged}
                selectedMenuItem={selectedMenuItem}
                onCreateNew={props.onCreateNewRate}
              />
            </div>
            <div className="px-2">
              <Title level={4}>Rate Lines</Title>
              <RateLineGrid
                onSelectionChanged={handleSelectionChanged}
                selectedMenuItem={selectedMenuItem}
                onCreateNew={props.onCreateNewRateLine}
              />
            </div>
          </div>
        ) : (
          <Empty />
        )}
      </Content>
    </Layout>
  );
};
