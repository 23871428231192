import { AgGridReact } from 'ag-grid-react';
import tw, { styled } from 'twin.macro';

export const GridWrapper = styled(AgGridReact)`
  ${tw`relative m-0 p-0 w-full overflow-hidden`}

  .custom-tooltip {
    position: absolute;
    width: 150px;
    height: 70px;
    border: 1px solid cornflowerblue;
    transition: opacity 1s;
    pointer-events: none;
  }

  .custom-tooltip.ag-tooltip-hiding {
    opacity: 0;
  }

  .custom-tooltip p {
    margin: 5px;
    white-space: nowrap;
  }

  .custom-tooltip p:first-of-type {
    font-weight: bold;
  }

  height: inherit;

  .ag-root-wrapper,
  .ag-root-wrapper-body {
    height: inherit !important;
  }

  .ag-sort-order {
    font-size: 10px !important;
    position: relative;
    font-weight: 800;
    top: -4px;
    left: -2px;
    padding: 2px 4px;
    border-radius: 6px;
    border: 0;
  }

  .ag-theme-alpine {
    tr {
      ${tw`text-black-200`}
      & span .icon {
        ${tw`text-mantanee-900`}
      }
    }
  }

  .ag-icon {
    vertical-align: super;
  }

  .ag-header-cell {
    padding-right: 3px;
    background-color: #f7f6f7;
    padding-left: 3px;

    .ag-checkbox {
      padding: 6px;
    }
    .ag-input-field-input {
      border: 1px solid #d9d9d9;
      border-radius: 4px !important;
      height: 28px !important;
    }
  }

  .ag-selection-checkbox {
    margin: 0;
    position: relative;
    left: 8%;
  }

  .ag-sort-order {
    padding-bottom: 0 !important;
    left: 0 !important;
  }

  .ag-header-cell-label {
    ${tw`flex-wrap`}
    font-weight: 600;
    color: #8d8d8d;

    .ag-header-cell-text {
      overflow: initial !important;
    }
  }

  .truncate {
    text-overflow: clip !important;
  }

  .ag-cell-inline-editing {
    height: inherit !important;
    color: black !important;
  }

  .flag-row-yellow {
    background-color: yellow !important;
  }

  .ag-theme-alpine
    .ag-cell-wrapper
    > *:not(.ag-cell-value):not(.ag-group-value) {
    height: inherit;
  }
  .ag-details-row {
    padding: 20px;
    border: 1px solid #f0f0f0 !important;
  }

  .ag-row-odd {
    background-color: #f0f0f0;
  }

  .ag-cell {
    line-height: 24px !important;
    padding-right: 0 !important;
    .ant-input-number-input {
      text-align: center !important;
    }
    padding-left: 2px !important;
    display: flex;
    align-items: center;
    width: 100%;
    border-right: solid #cccccc 1px !important;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
  }

  .ag-react-container {
    width: inherit;
  }

  .ag-cell .ag-icon {
    vertical-align: unset;
  }

  //CONFLICT
  .ag-floating-filter-button {
    display: none;
  }
  .ag-root-wrapper {
    border: 1px solid rgba(0, 0, 0, 0.1);

    .ag-row-level-1 {
      background-color: white !important;
    }
    .ag-row-group {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      ${tw`items-center`}

      .ag-group-expanded,
      .ag-group-contracted {
        margin-right: 6px !important;
      }
    }
  }

  .ag-panel-title-bar-title {
    font-weight: 600;
  }

  /* grouping row */
  .ag-column-drop-cell-text {
    font-size: 12px;
  }

  .ag-column-drop-wrapper {
    .ag-column-drop-horizontal {
      height: 32px;
    }
  }

  .ag-tool-panel-wrapper {
    width: 280px !important;
    .ag-virtual-list-item {
      .pinned-col {
        .ag-column-select-column-label::after {
          content: '📌';
          font-size: 1em;
        }

        .ag-drag-handle {
          visibility: hidden !important;
        }
      }
    }
  }
`;
